import isClotinoLocale, { ClotinoLocale } from './Locales'

export function localeFromString(str: string) {
	const result = nullableLocaleFromString(str)
	if (!result) {
		throw new Error(`Invalid locale key ${str}`)
	}
	return result
}
export function nullableLocaleFromString(str: string) {
	str = str.toLowerCase()
	if (isClotinoLocale(str)) {
		return str as ClotinoLocale
	}
	return null
}

export function localeFromStringFallback(str: string): ClotinoLocale {
	return nullableLocaleFromString(str) ?? 'en'
}
