import { GetServerSidePropsContext, NextPageContext } from 'next'
import { localeFromString } from './localeFromString'
import { storeFromString } from './storeFromString'

import nookies from 'nookies'
import { CLOTINO_CURRENCY } from '../locales/locales'
import { CLOTINO_STORE_COOKIE_KEY } from '../shared'
import { AppContextType } from 'next/dist/next-server/lib/utils'
import { NextRouter } from 'next/router'

export function contextInfo(context: GetServerSidePropsContext) {
	return getContextInfo(context, context)
}

export function appContextInfo(app: AppContextType) {
	return getContextInfo(app.ctx, app.router)
}

function getContextInfo(
	context: NextPageContext | GetServerSidePropsContext,
	req: NextRouter | GetServerSidePropsContext
) {
	const cookies = nookies.get(context)
	const store = req.query.currency
		? String(req.query.currency)
		: ((cookies[CLOTINO_STORE_COOKIE_KEY] ?? 'czk') as CLOTINO_CURRENCY)

	return {
		locale: localeFromString(req.locale ?? req.defaultLocale ?? ''),
		store: storeFromString(store),
	}
}
